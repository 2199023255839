html,
body {
  background: #F4F5F7;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

@font-face {
  font-family: "Rubik";
  src: url("/src/assets/fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik Bold";
  src: url("/src/assets/fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik Medium";
  src: url("/src/assets/fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Rubik", sans-serif;
  color: #2E2E2E;
}

.react-tel-input .country-list {
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.react-tel-input .country-list .flag {
  display: none;
}

.react-tel-input .country-list .country {
  padding: 8px 16px 7px;
  text-align: left;
  color: #070707;
}

.react-tel-input .country-list .country:hover {
  background: #E9EAEE;
}

.react-tel-input .country-list .country .dial-code {
  color: #ACB1B6;
}

.react-tel-input .form-control {
  width: 94px;
}

.react-tel-input  {
  position: absolute;
  height: 48px;
  width: 94px;
}

.react-tel-input .form-control {
  display: none;
}

.react-tel-input .flag-dropdown {
  width: 100%;
  background-color: transparent;
  border: 1px solid #ACB1B6;
  border-radius: 8px 0 0 8px;
}

.react-tel-input .flag-dropdown:hover {
  border: 1px solid #ACB1B6;
}

.react-tel-input .selected-flag .flag {
  right: 26px;
}

.react-tel-input .selected-flag {
  width: 100%;
  border-radius: 8px 0 0 8px;
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open {
  background: transparent;
  border: 1px solid #FFDA17;
  border-radius: 8px 0 0 8px;

}

a:hover {
  color: #F0BE0C;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
  border-radius: 8px 0 0 8px;
}

.react-tel-input .selected-flag .flag {
  background-image: none;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #070707;
  border-left: 5px solid transparent;
  border-right: 4px solid transparent;
  left: 24px;
}

.spinner-border {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(rgba(101, 108, 119, 0.25), #0C0B07);
  position: relative;
  animation: spin 1.4s linear infinite;
  transform: translateZ(0);
  border:none;
}

.spinner-border::after {
  background: #F0BE0C;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
